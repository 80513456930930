import { FC } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import routes from './config/routes';
import { useIsAuthenticated } from './hooks/authHooks';
import AuthLayout from './layouts/AuthLayout';
import NoAuthLayout from './layouts/NoAuthLayout';
import DebugPage from './pages/DebugPage';
import HistoryListPage from './pages/HistoryListPage';
import HistorySinglePage from './pages/HistorySinglePage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import RegisterPage from './pages/RegisterPage';
import CertifyPage from './pages/certificate/CertifyPage';
import RecordCheckPage from './pages/faq/RecordCheckPage';
import LoginPage from './pages/noAuth/LoginPage';
import LoginVerifyPage from './pages/noAuth/LoginVerifyPage';

const AppRoutes: FC = () => {
  return (
    <Routes>
      {/* Edit src/config/routes.ts too. */}
      <Route path="/" element={<IndexPage />} />
      <Route path="/" element={<NoAuthRouteLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="login/verify" element={<LoginVerifyPage />} />
        <Route path="help/record" element={<RecordCheckPage />} />
      </Route>
      <Route path="/" element={<AuthRouteLayout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="history" element={<HistoryListPage />} />
        <Route path="history/:certificateId" element={<HistorySinglePage />} />
        <Route path="certify" element={<CertifyPage />} />
        <Route path="debug" element={<DebugPage />} />
      </Route>
      <Route path="*" element={<>not found</>} />
    </Routes>
  );
};

export default AppRoutes;

const IndexPage = () => {
  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated) {
    return <Navigate to={routes.home} />;
  }

  return <Navigate to={routes.login} />;
};

const NoAuthRouteLayout = () => (
  <NoAuthLayout>
    <Outlet />
  </NoAuthLayout>
);

const AuthRouteLayout = () => (
  <AuthLayout>
    <Outlet />
  </AuthLayout>
);
