import { List, Divider, ListItem, Avatar } from '@mui/material';

import { PageLinkButtonList } from '../components/domain/PageLinkButton';
import { PrivacyLink, TermsLink } from '../components/domain/TermsAndPrivacy';
import { Section, FlexCenter } from '../components/shared/Boxes';
import Separator from '../components/shared/Separator';
import StickyNote from '../components/shared/StickyNote';
import {
  Label,
  LabelTitle,
  Body,
  Caption,
} from '../components/shared/Typographies';
import { useAuthUser } from '../hooks/authHooks';

const HomePage = () => {
  const trainer = useAuthUser().trainer;
  const capability = useAuthUser().capability;
  return (
    <>
      <Section>
        {trainer && (
          <FlexCenter column>
            <Avatar
              alt={trainer.fullname}
              src={trainer.image.url}
              sx={{ width: 125, height: 125 }}
            />
            <Label>{trainer.fullname}</Label>
          </FlexCenter>
        )}
      </Section>
      <Section>
        <StickyNote>
          <Body center>
            <LabelTitle>これまでの認定試験の回数</LabelTitle>
            <Label>{capability.numCertified} 回</Label>
          </Body>
        </StickyNote>
      </Section>
      <Section>
        <Label>お知らせ</Label>
        <Notices />
      </Section>

      <Section>
        <PageLinkButtonList
          pageKeys={['certify', 'history', 'profile', 'help/record', 'logout']}
        />
      </Section>

      <Separator />

      <Section>
        <Caption>
          <FlexCenter columnGap={1}>
            <TermsLink />
            <PrivacyLink />
          </FlexCenter>
        </Caption>
      </Section>
    </>
  );
};

export default HomePage;

const Notices = () => {
  return (
    <>
      <List>
        <ListItem disablePadding>
          認定をする際は事前に 「動画撮影チェック」
          のページから、動画撮影と効果音再生のテストを済ませてください。
        </ListItem>
      </List>
      <Divider />
    </>
  );
};
