import routes from './routes';

const ALL_KEYS_AS_CONST = [
  'certify',
  'history',
  'profile',
  'logout',
  'help/record',
] as const;
export type PageKey = typeof ALL_KEYS_AS_CONST[number];
// const ALL_KEYS = [...ALL_KEYS_AS_CONST] as PageKey[];

export type PageConfigItem = {
  key: PageKey;
  label: string;
  path: string;
  icon?: string;
  secondary?: boolean;
};

type PageConfigMap = {
  [k in PageKey]: PageConfigItem;
};

const pageConfigItems: PageConfigItem[] = [
  {
    key: 'certify',
    label: '認定試験を開始',
    path: routes.certify,
    icon: 'history',
  },
  {
    key: 'history',
    label: '認定履歴を確認',
    path: routes.history,
    icon: 'directions_run',
  },
  {
    key: 'profile',
    label: 'プロフィール情報',
    path: routes.profile,
    icon: 'account_box',
  },
  {
    key: 'help/record',
    label: '動画撮影チェック',
    path: routes.help.record,
    icon: 'videocam',
    secondary: true,
  },
  {
    key: 'logout',
    label: 'ログアウト',
    path: '#',
    icon: 'logout',
    secondary: true,
  },
];

const pageConfigMap: PageConfigMap = pageConfigItems.reduce<
  Partial<PageConfigMap>
>((res, item) => {
  res[item.key] = item;
  return res;
}, {}) as PageConfigMap;

export const getPageConfigItems = (pageKeys: PageKey[]): PageConfigItem[] => {
  return pageKeys.map((key) => pageConfigMap[key]);
};
