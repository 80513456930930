import { FC } from 'react';

import CertifyCtx, {
  StepType,
  useCertifyCtx,
  useCertifyState,
} from '../../contexts/CertifyCtx';
import { useResetScrollEffect } from '../../hooks/utilHooks';
import CertifyLayout from './CertifyLayout';
import CompleteStep from './CompleteStep';
import InfoStep from './InfoStep';
import MovieStep from './MovieStep';
import PetStep from './PetStep';
import ResultStep from './ResultStep';

const CertifyPage = () => {
  const { ctxValue } = useService();

  return (
    <CertifyCtx.Provider value={ctxValue}>
      <CertifyLayout>
        <Steps />
      </CertifyLayout>
    </CertifyCtx.Provider>
  );
};
export default CertifyPage;

const STEP_TO_COMPONENT: { [k in StepType]: FC<{}> } = {
  pet: PetStep,
  info: InfoStep,
  movie: MovieStep,
  result: ResultStep,
  uploading: ResultStep,
  complete: CompleteStep,
};

const Steps = () => {
  const { step } = useCertifyCtx();
  useResetScrollEffect([step]);
  const Component = STEP_TO_COMPONENT[step.type];
  return <Component />;
};

const useService = () => {
  const ctxValue = useCertifyState();

  return {
    ctxValue,
  };
};
